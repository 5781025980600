import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRoute, RoutesEnum } from 'src/config/routes';
import { useSetEmptyDefaultService } from 'src/features/accountManagement/hooks';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import userSelector from 'src/store/user/userSelector';
import { useAppDispatch } from './useAppDispatch';

export const useSetPrimaryServiceModal = () => {
  const { mutate } = useSetEmptyDefaultService();
  const user = useSelector(userSelector);
  const dispatch = useAppDispatch();
  const route = getRoute(window.location.pathname);

  useEffect(() => {
    if (
      route?.id !== RoutesEnum.VODAFONE_ID_CONFIRM &&
      !user.isWebView &&
      user.signedIn &&
      user.grantedRoles.length > 0 &&
      user.actualGrantedRole &&
      !!user.subscription &&
      user.defaultServiceNumber === null &&
      user.subscriptions &&
      user.subscriptions.length > 1
    ) {
      dispatch(
        openModal({
          type: ModalType.PrimaryServiceModal,
          options: {
            onClose: () => {
              mutate();
            },
          },
        }),
      );
    }
  }, [user]);
};
