import {
  MicrositeDirectOfferFlow,
  MicrositeFlow,
} from '../types/genericMicrosite/genericMicoristeFlows';
import { LanguagesEnum } from '../types/translation/languagesEnum';
import { IApplicationState } from './appStateInterface';
import {
  NoticeStyleModifiersEnum,
  NoticeTypesEnum,
} from './notice/noticeEnums';
import UserAuthorizationRoleEnum from './user/types/UserAuthorizationRoleEnum';

const initialState: IApplicationState = {
  Application: {
    device: null,
    apiLoading: [],
    previousPage: '',
    currentPage: '',
  },
  Billing: {
    PaymentInfo: {
      loading: false,
      loaded: false,
      error: false,
      data: {},
      baNumber: null,
    },
    PaymentTransaction: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    PaymentStatus: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    BillingCustomer: {
      data: {},
      selectedCaNumber: null,
    },
    PayerPayment: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    UpcInvoice: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    BillingAccount: {
      isSet: false,
    },
  },
  Cas: {
    refreshSubscription: {
      loading: false,
      loaded: false,
      error: false,
      data: {},
    },
    resetPin: {
      loading: false,
      loaded: false,
      error: false,
      data: {},
    },
  },
  CurrentSpending: {
    loading: false,
    loaded: false,
    error: false,
    data: {},
  },
  Documents: {
    invoices: {
      loading: false,
      loaded: false,
      invoices: [],
      error: false,
    },
    agreements: {
      loading: false,
      loaded: false,
      agreements: [],
      error: false,
    },
    agreementsByType: {
      loading: false,
      loaded: false,
      agreements: [],
      error: false,
    },
    forms: {
      loading: false,
      loaded: false,
      forms: [],
      error: false,
    },
    relatedContent: {
      loading: false,
      loaded: false,
      data: [],
      error: false,
    },
    orderPreview: {
      loading: false,
      loaded: false,
      data: [],
      error: false,
    },
  },
  EETReceipts: {
    data: [],
    loading: false,
    loaded: false,
    error: false,
  },
  ESim: {
    simSwap: {
      eId: false,
      qrCode: false,
      loading: false,
      loaded: false,
      error: false,
    },
    eSimDelivery: {
      eId: false,
      qrCode: false,
      loading: false,
      loaded: false,
      error: false,
    },
    eSimDetail: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    qrCode: {
      loading: false,
      loaded: false,
      error: false,
    },
    activateSimOrESim: {
      loading: false,
      loaded: false,
      error: false,
    },
    oneNumberPortal: {
      data: {},
    },
    oneNumberNumbers: {
      data: {},
    },
    smsForkSettings: {
      loading: false,
      loaded: false,
      error: false,
    },
  },
  Faqs: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  FbbServices: {
    ipManagement: {
      loading: false,
      loaded: false,
      error: false,
      totalPrice: 0,
      purchaseAvailable: true,
      ipAddressLimit: 10,
      ipAddresses: [],
      status: null,
    },
    fbbModemMacAddress: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    fbbModemRestart: {
      loading: false,
      loaded: false,
      error: false,
    },
    fbbExitServicePenalty: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
  },
  GenericMicrosites: {
    errorMessage: '',
    jsonData: '',
    htmlData: '',
    micrositeParams: {
      module: '',
    },
    micrositeFlow: MicrositeFlow.DIRECT_OFFER_CLICK,
    micrositeFlowState: MicrositeDirectOfferFlow.INITIAL,
    micrositeLeadState: {
      leadSent: false,
      leadSentLoading: false,
      leadSentSuccess: false,
    },
    loading: true,
    forcePageReload: false,
  },
  GenericMicrositeAfterLogin: {
    loaded: false,
    signedIn: false,
    selectedService: undefined,
    otherServices: [],
    searchQuery: '',
    searchResult: [],
  },
  InactiveUserModal: {
    active: true,
    warningModalIsVisible: false,
  },
  Invoices: {
    invoices: {
      loading: false,
      loaded: false,
      error: false,
      data: {},
    },
    breakdown: {
      loading: false,
      loaded: false,
      error: false,
      data: {},
    },
    summary: {
      data: {},
      selectedBaNumber: null,
    },
  },
  Language: {
    language: LanguagesEnum.CS,
  },
  LargeAccounts: {
    preactivatedESims: {
      data: {},
    },
  },
  Loading: {
    number: 1,
    initialLoading: true,
  },
  Mfa: {},
  Mnp: {
    oku: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    cvop: {
      loading: false,
      loaded: false,
      error: false,
    },
    serviceDetail: {
      loading: false,
      loaded: false,
      error: false,
      mnpType: null,
      data: null,
      authType: null,
    },
    penalty: {
      loading: false,
      loaded: false,
      error: false,
    },
  },
  Modal: {
    type: null,
    isOpen: false,
  },
  MteChange: {
    loading: false,
    loaded: false,
    error: false,
  },
  News: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  Notice: {
    settings: {
      title: 'Notice initial title',
      message: [
        {
          text: 'Notice initial message',
          index: 0,
        },
      ],
      noticeType: NoticeTypesEnum.neutral,
      noticeStyleModifier: NoticeStyleModifiersEnum.neutral,
    },
    isActive: false,
  },
  PasswordForgotConfirmation: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  PegaOffers: {},
  PinPuk: {},
  ProductsOverview: {
    loading: false,
    loaded: false,
    error: false,
    data: {},
  },
  RecoveryEligibility: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  RecoveryEligibilityCaptcha: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  RecoveryRequest: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  RecoveryRequestCaptcha: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  RecoverySetting: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  RequestValidationSms: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  Services: {
    services: {
      loading: false,
      loaded: false,
      error: false,
      items: [],
      allServices: {
        loading: false,
        loaded: false,
        error: false,
      },
    },
    sections: {
      loading: false,
      loaded: false,
      error: false,
      items: [],
    },
    moreServices: {
      groupToExpand: null,
      loading: false,
      loaded: false,
      error: false,
    },
    tariffOverview: {
      loading: false,
      loaded: false,
      error: false,
      isOpen: false,
      tariff: null,
    },
    virtualBundle: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    managementEligibility: {
      error: false,
      hasOpenOrder: false,
      hasTariffMigrationInCart: false,
      isTariffMigrationEligible: false,
      isZame: false,
      loaded: false,
      loading: false,
    },
    roamingCalculator: {
      subscribers: {},
      countries: {},
      loaded: false,
      loading: false,
      error: false,
    },
    simActivation: {
      loaded: false,
      loading: false,
      error: false,
      data: null,
    },
  },
  SmartOverview: {
    loading: false,
    loaded: false,
    error: false,
    data: {},
  },
  UsageConsumption: {
    data: {},
    selectedBaNumber: null,
  },
  User: {
    username: '',
    authorizationRoleName: UserAuthorizationRoleEnum.User,
    signedIn: null,
    tokenLevel: null,
    email: '',
    segment: null,
    isPersonalAccount: false,
    actualGrantedRole: null,
    grantedRoles: [],
    defaultRoleId: null,
    subscription: {
      subscriptionId: null,
      billingAccountNumber: null,
      serviceNumber: null,
      type: null,
      paymentType: null,
      nickname: null,
    },
    subscriptions: [],
    largeSubscribers: {
      data: {},
      loading: false,
      loaded: false,
      error: false,
    },
    billingAccounts: [],
    billingAccount: null,
    largeBillingAccounts: {},
    largeBillingAccountsLoading: false,
    caNumber: '',
    isLargeAccount: false,
    isMegaLargeAccount: false,
    engagedPrincipal: null,
    isWebView: false,
    roles: [],
    globalPageData: {},
    loaded: false,
    loading: false,
    error: false,
    defaultServiceNumber: '',
  },
  VerifiedSmsCode: {
    loading: false,
    loaded: false,
    error: false,
    data: null,
  },
  VtvServices: {
    loading: false,
    loaded: false,
    error: false,
    activeDevices: [],
    activeDevicesLimit: 0,
    activeSetTopBoxDevices: [],
    casSetTopBoxDevices: [],
    removeDevice: {
      udid: null,
      loading: false,
      loaded: false,
      error: false,
    },
    generatePassword: {
      loading: false,
      loaded: false,
      error: false,
    },
    setPassword: {
      loading: false,
      loaded: false,
      error: false,
    },
    setPurchasePin: {
      loading: false,
      loaded: false,
      error: false,
    },
  },
  RecurringDataOffer: {
    Offer: {
      loading: false,
      loaded: false,
      error: false,
      data: null,
    },
    Activation: {
      loading: false,
      loaded: false,
      error: false,
    },
  },
};

export default initialState;
