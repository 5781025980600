import { RoutesEnum } from './routes';

export const IDLE_DEBOUNCE = 1000; // in milliseconds
export const IDLE_WARNING_TIMEOUT = 840000; // in milliseconds (14minutes)
export const IDLE_LOGOUT_TIMEOUT = 1000 * 60; // in milliseconds (1minutes) -> logout after 15 minutes (1 minute after showWarning())

export const STB_DOWNLOAD_MANUAL_URL =
  'https://www.vodafone.cz/_sys_/FileStorage/download/2/1305/vtv_stb_manual.pdf';
// we need to get valid URLs - only proxy link now
export const MODEM_CONTRACT_URL = '/muj/ucet/smlouva-a-splatky';

export const INTERNAL_SUBDOMAIN = 'www-internal';

export const DATE_FORMAT = 'd.m.yyyy';
export const DATE_FORMAT_WITH_HOURS = 'd.m.yyyy H:MM';

export const AUTO_OPEN_MODALS_PREFIX_IN_HASH = '#modal-';

// caching times in seconds
export const CACHE_TIME_FOR_CART_COUNT_RESPONSE = 240; // 4mins
export const CACHE_TIME_FOR_UNREAD_VF_NEWS = 240 * 1000; // 4mins

export const GDPR_EMAIL_LOGIN_PDF =
  'https://www.vodafone.cz/_sys_/FileStorage/download/2/1708/1prihlasovani-e-mailem.pdf?nc=1';

// Routy, ktere neredirectuji na stranku /muj/profil
export const REDIRECT_TO_PERSONAL_ACCOUNT_DISABLED_ROUTES = [
  RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
  RoutesEnum.PERSONAL_ACCOUNT_ADD_ACCOUNT,
  RoutesEnum.LOGIN_ROUTE_ID,
];

export const LOGIN_YOUTUBE_VIDEO_ID = 'shF7i2k6NrA';
