import {
  useMutation,
  useMutationState,
  useQueryClient,
} from '@tanstack/react-query';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { fetchSession } from 'src/store/user/userActions';
import api from 'src/utils/api/api';

export const POST_ACCOUNT_MANAGEMENT_SETTINGS_DEFAULT_SERVICE_ENDPOINT =
  '/muj/rapi/account-management/settings/default-service';
const POST_ACCOUNT_MANAGEMENT_SETTINGS_DEFAULT_SERVICE_KEY =
  'updateDefaultService';

type UpdateDefaultServiceRequest = {
  primaryServiceServiceNumber: string;
};

export const EMPTY_DEFAULT_SERVICE_VALUE = '';

const postAccountManagementSettingsDefaultService = async (
  request: UpdateDefaultServiceRequest,
): Promise<void> => {
  const { primaryServiceServiceNumber } = request;
  const { data } = await api.post<void>({
    url: POST_ACCOUNT_MANAGEMENT_SETTINGS_DEFAULT_SERVICE_ENDPOINT,
    data: {
      serviceNumber: primaryServiceServiceNumber,
    },
  });

  return data;
};

export const useUpdateDefaultService = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation<void, void, UpdateDefaultServiceRequest>({
    mutationFn: postAccountManagementSettingsDefaultService,
    mutationKey: [POST_ACCOUNT_MANAGEMENT_SETTINGS_DEFAULT_SERVICE_KEY],
    onSuccess: () => {
      // TODO: remove this line after merge into R2410
      dispatch(fetchSession(true));

      // TODO: preparation for R2410. only change the queryKey call!
      queryClient.invalidateQueries({
        queryKey: ['coreSessionQueryKey_REPLACE_WITH_CORRECT_CONST'],
      });
    },
  });
};

export const useSetEmptyDefaultService = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const isAlreadyMutated =
    useMutationState({
      filters: {
        mutationKey: [POST_ACCOUNT_MANAGEMENT_SETTINGS_DEFAULT_SERVICE_KEY],
      },
    }).length > 0;

  return useMutation({
    mutationFn: () => {
      if (!isAlreadyMutated) {
        return postAccountManagementSettingsDefaultService({
          primaryServiceServiceNumber: EMPTY_DEFAULT_SERVICE_VALUE,
        });
      }
    },
    mutationKey: [POST_ACCOUNT_MANAGEMENT_SETTINGS_DEFAULT_SERVICE_KEY],
    onSuccess: () => {
      // TODO: remove this line after merge into R2410
      dispatch(fetchSession(true));

      // TODO: preparation for R2410. only change the queryKey call!
      queryClient.invalidateQueries({
        queryKey: ['coreSessionQueryKey_REPLACE_WITH_CORRECT_CONST'],
      });
    },
  });
};
