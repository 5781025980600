import {
  IconBroadbandOrWifi,
  IconChevronDown,
  IconChevronUp,
  IconFixsub,
  IconMobileSystem,
  IconTV,
  InputRedesign,
} from '@vodafone/red-white';
import React, { useEffect, useRef, useState } from 'react';
import { HStack } from 'src/components/Layout';
import { Asset } from 'src/features/profile';
import { useDebounce } from 'src/hooks';
import styled from 'styled-components';

type Option = {
  value: string;
  roleId: string;
  assetType?: Asset;
};

type CustomSelectProps = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  setSelectedRoleId?: (value: string) => void;
  debounceTimeout?: number;
  label?: string;
  disabled?: boolean;
};

export const SelectWithInput = ({
  options,
  value,
  onChange,
  setSelectedRoleId,
  label,
  debounceTimeout = 300,
  disabled = false,
}: CustomSelectProps) => {
  const [initialInputValue, setInitialInputValue] = useState(value);

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const debouncedFilter = useDebounce(inputValue, debounceTimeout);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => {
        return option.value
          .toLowerCase()
          .includes(debouncedFilter.toLowerCase());
      }),
    );
  }, [debouncedFilter, options]);

  useEffect(() => {
    setInitialInputValue(value);
    setInputValue(value);
    setIsOpen(false);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setInputValue(initialInputValue);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const userAssetTypeIconsMap = new Map<Asset, React.ReactNode>([
    ['mobile', <IconMobileSystem />],
    ['tv', <IconTV />],
    ['fixed', <IconFixsub />],
    ['broadband', <IconBroadbandOrWifi />],
  ]);

  const handleOptionClick = (option: Option) => {
    onChange(option.value);
    setIsOpen(false);
    setInputValue(option.value);
    setSelectedRoleId?.(option.roleId);
    setInitialInputValue(option.value);
  };

  return (
    <SelectContainer ref={selectRef}>
      <InputRedesign
        disabled={options.length <= 1 || disabled}
        label={label}
        id={inputValue}
        type="text"
        value={inputValue}
        icon={
          options.length > 1 ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsOpen((prev) => (options.length > 1 ? !prev : false));
                setFilteredOptions(options);
              }}
            >
              {isOpen ? (
                <IconChevronUp color={'#e60000'} />
              ) : (
                <IconChevronDown color={'#e60000'} />
              )}
            </div>
          ) : (
            <></>
          )
        }
        onClick={() => {
          setIsOpen(options.length > 1 && true);
          setFilteredOptions(options);
        }}
        onChange={(e) => {
          if (!isOpen) {
            setIsOpen(true);
          }

          setInputValue(e.target.value);
          setSelectedRoleId?.(e.target.id);
        }}
      />
      {isOpen && (
        <OptionsContainer>
          {filteredOptions.map((option) => (
            <OptionItem
              key={option.roleId}
              onClick={() => handleOptionClick(option)}
            >
              <HStack align="center">
                {option.assetType && (
                  <span style={{ width: '25px', height: '25px' }}>
                    {userAssetTypeIconsMap.get(option.assetType)}
                  </span>
                )}
                <span style={{ marginLeft: option.assetType ? '10px' : '0px' }}>
                  {option.value}
                </span>
              </HStack>
            </OptionItem>
          ))}
        </OptionsContainer>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 5px;
  background: white;
  border: 1px solid #666666;
  border-top: none;
  max-height: 150px;
  z-index: 100;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #888 #f1f1f1;
  }
`;

const OptionItem = styled.div`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
`;
