import ChangeNicknameModalContent from 'src/components/Modal/components/ChangeNicknameModalContent';
import ActivateDeletedESimContent from 'src/components/Modal/components/ESim/ActivateDeletedESimContent';
import ActivateESimContent from 'src/components/Modal/components/ESim/ActivateESimContent';
import OrderNewESimMfaContent from 'src/components/Modal/components/ESim/OrderNewESimMfaContent';
import PreactivatedESimContent from 'src/components/Modal/components/ESim/PreactivatedESimContent';
import TransferESimContent from 'src/components/Modal/components/ESim/TransferESimContent';
import GrantAccessModalContent from 'src/components/Modal/components/GrantAccessModalContent';
import { MessageModalContent } from 'src/components/Modal/components/MessageModalContent';
import MfaModalContent from 'src/components/Modal/components/Mfa/MfaModalContent';
import MnpInternetOkuModalContent from 'src/components/Modal/components/MnpInternetOkuModalContent';
import MnpInternetPenaltyModalContent from 'src/components/Modal/components/MnpInternetPenaltyModalContent';
import RecurringDataActivatedContent from 'src/components/Modal/components/RecurringDataActivatedContent';
import RemoveAccessModalContent from 'src/components/Modal/components/RemoveAccessModalContent';
import ModalType from 'src/store/modal/types/ModalType';
import ChangeContactNumberContent from '../components/ChangeContactNumberContent';
import ConnectedDevicesToModemModalContent from '../components/ConnectedDevicesToModemModalContent';
import CreateServiceRequestModalContent from '../components/CreateServiceRequestModalContent';
import CustomerIdentityLoginBannerModalContent from '../components/CustomerIdentityLoginBannerModalContent';
import DeleteProfileModalContent from '../components/DeleteProfileModalContent';
import DifferentPaymentModalContent from '../components/DifferentPaymentModalContent';
import DiscountCodesModalContent from '../components/DiscountCodesModalContent/DiscountCodesModalContent';
import DownloadInvoicesInXlsModalContent from '../components/DownloadInvoicesInXlsModalContent';
import EETReceiptsModalContent from '../components/EETReceiptsModalContent';
import CompleteSimSwapContent from '../components/ESim/CompleteSimSwapContent';
import ESimInDeliveryContent from '../components/ESim/ESimInDeliveryContent';
import OrderNewESimContent from '../components/ESim/OrderNewESimContent';
import ESimDeviceNotificationSettingsModalContent from '../components/ESimDeviceNotificationSettingsModalContent';
import FbbModemSettingsContent from '../components/FbbModemSettingsContent';
import IotAndDataSimCardInfoModalContent from '../components/IotAndDataSimCardInfoModalContent';
import LostStolenConfirmModalContent from '../components/LostStolenConfirmModalContent';
import MnpCvopModalContent from '../components/MnpCvopModalContent';
import MnpOkuModalContent from '../components/MnpOkuModalContent';
import MnpPenaltyModalContent from '../components/MnpPenaltyModalContent';
import ModemDiagModalContent from '../components/ModemDiagModalContent';
import ModemFactoryResetModalContent from '../components/ModemFactoryResetModalContent';
import ModemRestartModalContent from '../components/ModemRestartModalContent';
import PaymentInfoModalContent from '../components/PaymentInfoModalContent';
import PaymentStatusModalContent from '../components/PaymentStatusModalContent';
import PrimaryServiceModalContent from '../components/PrimaryServiceModalContent';
import ReceivedPaymentsContent from '../components/ReceivedPaymentsContent';
import RoamingCalcModalContent from '../components/RoamingCalcModalContent';
import ShowPasswordModalContent from '../components/ShowPassword/ShowPasswordModalContent';
import SimActivationConfirmModalContent from '../components/SimActivationConfirmModalContent';
import SmsValidationModalContent from '../components/SmsValidationModalContent';
import UPCBillingHistoryContent from '../components/UPCBillingHistoryContent';
import { VideoModalContent } from '../components/VideoModalContent';
import VtvCasPinResetModalContent from '../components/VtvCasPinResetModalContent/VtvCasPinResetModalContent';
import VtvCasRefreshSubscriptionModalContent from '../components/VtvCasRefreshSubscriptionModalContent/VtvCasRefreshSubscriptionModalContent';
import VtvGeneratePasswordModalContent from '../components/VtvGeneratePasswordModalContent';
import VtvRemoveActiveDeviceModalContent from '../components/VtvRemoveActiveDeviceModalContent';
import VtvSetPasswordModalContent from '../components/VtvSetPasswordModalContent/VtvSetPasswordModalContent';
import VtvSetPasswordStatusModalContent from '../components/VtvSetPasswordStatusModalContent';

const modalContentMap = {
  [ModalType.Video]: VideoModalContent,
  [ModalType.ConnectedDevicesToModem]: ConnectedDevicesToModemModalContent,
  [ModalType.CreateServiceRequest]: CreateServiceRequestModalContent,
  [ModalType.ModemRestart]: ModemRestartModalContent,
  [ModalType.ModemFactoryReset]: ModemFactoryResetModalContent,
  [ModalType.ModemDiagModal]: ModemDiagModalContent,
  [ModalType.IotAndDataSimCardInfo]: IotAndDataSimCardInfoModalContent,
  [ModalType.ESimDeviceNotificationSettings]:
    ESimDeviceNotificationSettingsModalContent,
  [ModalType.Mfa]: MfaModalContent,
  [ModalType.SimActivationConfirm]: SimActivationConfirmModalContent,
  [ModalType.ShowPassword]: ShowPasswordModalContent,
  [ModalType.LostStolenConfirm]: LostStolenConfirmModalContent,
  [ModalType.CustomerIdentityLoginBanner]:
    CustomerIdentityLoginBannerModalContent,
  [ModalType.DifferentPayment]: DifferentPaymentModalContent,
  [ModalType.VtvRemoveActiveDevice]: VtvRemoveActiveDeviceModalContent,
  [ModalType.VtvGeneratePassword]: VtvGeneratePasswordModalContent,
  [ModalType.VtvSetPassword]: VtvSetPasswordModalContent,
  [ModalType.VtvSetPasswordStatus]: VtvSetPasswordStatusModalContent,
  [ModalType.VtvCasPinReset]: VtvCasPinResetModalContent,
  [ModalType.VtvCasRefreshSubscription]: VtvCasRefreshSubscriptionModalContent,
  [ModalType.MnpOku]: MnpOkuModalContent,
  [ModalType.MnpInternetOku]: MnpInternetOkuModalContent,
  [ModalType.MnpCvop]: MnpCvopModalContent,
  [ModalType.MnpPenalty]: MnpPenaltyModalContent,
  [ModalType.MnpInternetPenalty]: MnpInternetPenaltyModalContent,
  [ModalType.GrantAccess]: GrantAccessModalContent,
  [ModalType.DeleteProfile]: DeleteProfileModalContent,
  [ModalType.RoamingCalc]: RoamingCalcModalContent,
  [ModalType.PaymentInfo]: PaymentInfoModalContent,
  [ModalType.PaymentStatus]: PaymentStatusModalContent,
  [ModalType.RemoveAccess]: RemoveAccessModalContent,
  [ModalType.ChangeNickname]: ChangeNicknameModalContent,
  [ModalType.UPCBillingHistory]: UPCBillingHistoryContent,
  [ModalType.ReceivedPayments]: ReceivedPaymentsContent,
  [ModalType.SmsVerification]: SmsValidationModalContent,
  [ModalType.DownloadInvoicesInXls]: DownloadInvoicesInXlsModalContent,
  [ModalType.EETReceipts]: EETReceiptsModalContent,
  [ModalType.FbbModemSettings]: FbbModemSettingsContent,
  [ModalType.ChangeContactNumber]: ChangeContactNumberContent,
  [ModalType.OrderNewESim]: OrderNewESimContent,
  [ModalType.OrderNewESimMfa]: OrderNewESimMfaContent,
  [ModalType.ActivateESim]: ActivateESimContent,
  [ModalType.PreactivatedESim]: PreactivatedESimContent,
  [ModalType.ActivateDeletedESim]: ActivateDeletedESimContent,
  [ModalType.TransferESim]: TransferESimContent,
  [ModalType.CompleteSimSwapContent]: CompleteSimSwapContent,
  [ModalType.ESimInDelivery]: ESimInDeliveryContent,
  [ModalType.RecurringDataActivated]: RecurringDataActivatedContent,
  [ModalType.DiscountCodes]: DiscountCodesModalContent,
  [ModalType.PrimaryServiceModal]: PrimaryServiceModalContent,
  [ModalType.MessageModal]: MessageModalContent,
};

export default modalContentMap;
