import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { AuthLevel } from 'src/components/PersonalAccount/VFIDProfile';
import userSelector from 'src/store/user/userSelector';
import api from 'src/utils/api/api';

export const GET_USER_ASSETS_ENDPOINT = '/muj/rapi/core/user-assets';
export const GET_USER_ASSETS_KEY = 'getUserAssetsKey';

export type Asset = 'broadband' | 'fixed' | 'tv' | 'mobile' | 'sec' | 'fms';

export type UserAsset = {
  name?: string;
  id: string;
  authLevel: AuthLevel;
  assetType?: Asset;
  grantable: boolean;
  relatedRole: RelatedUserRole;
};

type RelatedUserRole = {
  id: string;
  role: string;
};

export type GetUserAssetsResponse = {
  userAssets: {
    admin: UserAsset[];
    billingAdmin: UserAsset[];
    user: UserAsset[];
  };
};

const getUserAssetsFn = async (): Promise<GetUserAssetsResponse> => {
  const response = await api.get<GetUserAssetsResponse>({
    url: GET_USER_ASSETS_ENDPOINT,
  });

  return response.data;
};

export const useGetUserAssets = (grantableOnly = false) => {
  const user = useSelector(userSelector);

  return useQuery({
    queryKey: [GET_USER_ASSETS_KEY, grantableOnly],
    queryFn: () => getUserAssetsFn(),
    select: (response) => ({
      ...response,
      userAssets: grantableOnly
        ? {
            admin: response.userAssets.admin.filter((item) => item.grantable),
            billingAdmin: response.userAssets.billingAdmin.filter(
              (item) => item.grantable,
            ),
            user: response.userAssets.user.filter((item) => item.grantable),
          }
        : response.userAssets,
    }),
    enabled: user.loaded && user.signedIn && user.grantedRoles.length > 0,
  });
};
