import {
  GlobalStyle,
  LoaderFullPage,
  ThemeRedesign as themeRedesign,
} from '@vodafone/red-white';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import MetaDataDecorator from 'src/components/MetaDataDecorator';
import { useCoreConfig, useCoreOutage } from 'src/features/core';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import ChatResolver from './components/ChatResolver';
import { logComponentError } from './components/ErrorBoundary/actions/logActions';
import ErrorBoundary from './components/ErrorBoundary/components/ErrorBoundary';
import StyledErrorMessage from './components/ErrorBoundary/components/StyledErrorMessage';
import Loading from './components/Loading';
import MediaEnquire from './components/MediaEnquire';
import { MockDevTools } from './components/MockDevTools';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { configureI18next } from './config/i18next';
import routes, { isLoginRoute } from './config/routes';
import { AxiosProvider } from './context';
import { RefreshTokenProvider } from './context/RefreshTokenContext';
import './fonts/fonts.css';
import { useAfterLoginRedirect } from './hooks/useAfterLoginRedirect';
import { useBeforeunload } from './hooks/useBeforeUnload';
import { loadingStart } from './store/loading/loadingActions';
import { fetchSession } from './store/user/userActions';
import userSelector from './store/user/userSelector';
import BasicRoute from './utils/router/BasicRoute';
import PrivateRoute from './utils/router/PrivateRoute';

const ModalStyles = createGlobalStyle`
  body.modal-open {
    overflow-y: hidden;
  }
`;

const App = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);
  const config = useCoreConfig();
  const outage = useCoreOutage();

  useBeforeunload(() => {
    dispatch(loadingStart());
  });

  useEffect(() => {
    configureI18next();
    dispatch(fetchSession());
  }, [dispatch]);

  useAfterLoginRedirect();

  return (
    <>
      <ModalStyles />
      <ErrorBoundary
        onError={(error: Error, componentStack: string) =>
          dispatch(logComponentError(error, componentStack))
        }
        FallbackComponent={StyledErrorMessage}
      >
        <MediaEnquire />
        <GlobalStyle />
        <MockDevTools />

        <Loading displayLoading={user.loading} />
        <ThemeProvider theme={themeRedesign}>
          <AxiosProvider>
            <RefreshTokenProvider>
              <Router>
                <MetaDataDecorator />
                <ScrollToTop />
                {user.loaded && outage.isSuccess && (
                  <>
                    <ChatResolver />
                    <React.Suspense
                      fallback={
                        <>
                          {config.isSuccess &&
                            user.loaded &&
                            isLoginRoute() && (
                              <div
                                style={{
                                  background: '#A72116',
                                  position: 'absolute',
                                  top: 0,
                                  width: '100%',
                                  height: '100%',
                                }}
                              ></div>
                            )}
                          <LoaderFullPage show opacity={0.7} />
                        </>
                      }
                    >
                      <Switch>
                        {routes.map((route) =>
                          Object.keys(route.path).map((locale) =>
                            route.protected ? (
                              <PrivateRoute
                                key={route.id}
                                component={route.component}
                                exact
                                path={route.path[locale]}
                              />
                            ) : (
                              <BasicRoute
                                key={route.id}
                                component={route.component}
                                exact
                                path={route.path[locale]}
                              />
                            ),
                          ),
                        )}
                      </Switch>
                    </React.Suspense>
                  </>
                )}
              </Router>
            </RefreshTokenProvider>
          </AxiosProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
