import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';

type Options = {
  youtubeId: string;
};

type Props = {
  options: Options;
} & ModalContentProps;

export const VideoModalContent = ({
  options,
  setCompact,
  setCompactContent,
  setTransparentBg,
}: Props) => {
  const MAX_WIDTH = 940;
  const dispatch = useDispatch();
  const [width, setWidth] = useState(MAX_WIDTH);
  const ref = useRef(null);

  const updateWidth = () => {
    if (ref.current) {
      setWidth(window.innerWidth > 1000 ? MAX_WIDTH : ref.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
    setTransparentBg(true);
  }, [setCompactContent, setCompact, dispatch]);

  return (
    <div ref={ref}>
      <iframe
        style={{ border: 'none' }}
        width={width}
        height={Math.floor(width / 1.77)}
        src={`https://www.youtube.com/embed/${options.youtubeId}?autoplay=1`}
        title="YouTube video player"
        allow="accelerometer; autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
    </div>
  );
};
