import { useQuery } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export const GET_CAPTCHA_ENDPOINT =
  '/muj/rapi/form/recovery_eligibility/captcha';
const RECOVERY_CAPTCHA_KEY = 'recoveryCaptcha';

type GetCaptchaResponse = {
  captcha?: string;
  message?: string;
  status?: string;
};

const getCaptcha = async (): Promise<GetCaptchaResponse> => {
  const { data } = await api.get({ url: GET_CAPTCHA_ENDPOINT });

  return data;
};

export const useCaptcha = () => {
  return useQuery({
    queryFn: () => getCaptcha(),
    queryKey: [RECOVERY_CAPTCHA_KEY],
    retry: false,
  });
};
