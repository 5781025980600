import {
  BlogIcon,
  Card,
  Link,
  Message,
  Text,
  Title,
} from '@vodafone/red-white';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { SelectPrimaryService } from 'src/components/PersonalAccount/VFIDProfile';
import { RoutesEnum } from 'src/config/routes';
import selectLanguage from 'src/store/language/languageSelector';
import { redirect } from 'src/utils/redirect';
import styled from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 20px 0 0 0;
  text-align: center;
`;

const LinkText = styled(Link)`
  font-size: 16px;
  display: inline;
  padding: 0 4px;
  white-space: nowrap;
  text-decoration: underline;
`;

const IconWrapper = styled.div`
  @media ${(props) => props.theme.breakpoints.xsOnly} {
    display: none;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 34px;
  margin-top: 20px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.breakpoints.xsOnly} {
    font-size: 20px;
    margin-top: 10px;
  }
`;

const PrimaryServiceModalContent = ({
  setCompact,
  setCompactContent,
}: ModalContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('personalAccount');
  const language = useSelector(selectLanguage);

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  return (
    <Card
      style={{
        width: '100%',
        maxWidth: 662,
        boxShadow: 'none',
        maxHeight: '80vh',
      }}
    >
      <CustomCardPadding>
        <div>
          <IconWrapper>
            <BlogIcon size={96} />
          </IconWrapper>
          <StyledTitle
            color="darkGrey"
            fontFamily="VodafoneRgBd"
            fontWeight="bold"
            level={3}
            fontSize={34}
          >
            {t('primary-service.modal.title')}
          </StyledTitle>
          <Text block fontSize={16} style={{ marginBottom: 20 }}>
            {t('primary-service.modal.message')}
          </Text>
          <SelectPrimaryService
            displayLabels
            onErrorTransKey={'primary-service.modal.error'}
          />
          <div style={{ marginTop: '24px' }}>
            <Message
              type="info-blue"
              message={
                <div style={{ display: 'block', textAlign: 'left' }}>
                  <Trans
                    i18nKey="personalAccount:primary-service.modal.info"
                    components={[
                      <LinkText
                        onClick={() =>
                          redirect({
                            internalRouteId:
                              RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
                          })
                        }
                      />,
                    ]}
                  />
                </div>
              }
            />
          </div>
        </div>
      </CustomCardPadding>
    </Card>
  );
};

export default PrimaryServiceModalContent;
