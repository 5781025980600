import { IService } from 'src/store/services/types/service/serviceInterface';
import { API_ENDPOINT_SERVICES_BSS_ONW_DETAIL } from '../../../api';
import {
  bssOnwServices,
  BssServicesEnums,
} from '../../../types/services/bssServicesEnums';

export const initJqueryServiceDetail = (section, service: IService) => {
  if (section.hasClass('ready')) {
    return;
  }

  section.addClass('ready');

  const element = section.find('div.serviceChangeWrapper');
  const isEmpty = false; // service.detail.toString().trim().length === 0;

  // element.html(!isEmpty ? service.detail : VF.tools.renderMessage('info', Translate('service.status.cannot_modify')));
  if (isEmpty) {
    element.addClass('center');
  } else {
    element.removeClass('center');
  }

  window.VF.Services.callServiceInitializer(
    service.codeName || service.productOfferingId,
    section,
  );
  window.VF.initNiceFormFields(element);
  window.VF.initTooltips(element);
  window.VF.initDateOldPicker(element);
  window.VF.initAjaxFormsEventListener(element);
  window.VF.initPreventDefaultListener(element);
  window.VF.initTogglers(element);
  window.VF.Services.initDataMaster(section);
  window.VF.Services.dataLimitInitialize(section);
  window.VF.Services.initRoamingCc(section);
  window.VF.Services.absenceReasonInitialize(section);
  window.VF.Services.pvbInitializer(section);
  window.VF.Services.femtoInitializer(section);
  window.VF.Services.friendsInitialize(section);
  window.VF.Services.initDirectDebit(section);
  window.VF.initAjaxFormsEventListener(element);
  if (
    bssOnwServices.indexOf(service.productOfferingId as BssServicesEnums) !== -1
  ) {
    const onw = window.VF.OneNetWelcome();

    onw.render(
      API_ENDPOINT_SERVICES_BSS_ONW_DETAIL,
      service.productOfferingId,
      section,
    );
  }
};
