import { useIsMutating } from '@tanstack/react-query';
import {
  Button,
  IconCircleLoader,
  IconRefresh,
  InputRedesign as Input,
  Link,
  Message,
  Row,
} from '@vodafone/red-white';
import * as React from 'react';
import {
  Controller,
  ErrorOption,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { VStack } from 'src/components/Layout';
import {
  RECOVERY_ELIGIBILITY_MUTATION_KEY,
  useCaptcha,
} from 'src/features/passwordReset';
import { createIdaasResetPasswordUrl } from 'src/utils/login';
import serviceNumberValidator from 'src/utils/validators/serviceNumberValidator';
import styled, { ThemeContext } from 'styled-components';
import billExample from './resources/billExample.webp';

enum LoginTypeEnum {
  NUMBER = 'number',
}

export type FormValues = {
  serviceNumber: string;
  captcha: string;
  loginType?: LoginTypeEnum;
};

export type OnSubmitRequestForm = {
  data: FormValues;
  setError?: (
    fieldName: 'serviceNumber' | 'captcha',
    error: ErrorOption,
  ) => void;
  setNewCaptcha?: () => void;
};

export interface IRequestFormProps {
  onSubmit?: (props: OnSubmitRequestForm) => void;
}

const CardTitle = styled.span`
  margin-left: 8px;
`;

const CaptchaWrapper = styled.div`
  margin-right: 22px;
`;

const CaptchaTitleRow = styled(Row)`
  margin-bottom: 32px;
`;

const FormField = styled(Row)`
  margin-top: 0;
`;

const RefreshButton = styled(Link)`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.VodafoneRg};
  color: ${(props) => props.theme.colors.primaryColor};

  &:hover {
    color: ${(props) => props.theme.colors.primaryColor};
  }
`;

const RefreshButtonText = styled.span`
  margin-left: 9px;
`;

const ErrorText = styled.div`
  font-size: 14px;
  padding: 0;
  bottom: 0;
  width: 100%;
  margin-top: 5px;
  color: ${(props) => props.theme.colors.primaryColor};
  text-align: left;
`;

const ImageWrapper = styled.div`
  width: 130px;
  height: 50px;
  text-align: center;
  line-height: 47px;
`;

const LoginTypeHeader = styled.p`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.VodafoneRg};
  font-weight: bold;
  margin-bottom: 0;
`;

const MessageWrapper = styled.div`
  margin: 30px 0 30px 0;
`;

const MessageHeader = styled.strong`
  font-size: 16px;
`;

export const StyledUl = styled.ul`
  width: 100%;
  padding: 10px 0 20px 20px;
  font-size: 16px;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    padding: 0 0 10px 20px;
  }
`;

export const StyledLi = styled.li`
  font-family: ${(props) => props.theme.fonts.VodafoneRg};
  display: list-item;
  list-style-type: disc;
  margin-bottom: 10px;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    padding: 0;
  }
`;

export const StyledLink = styled(Link)`
  display: inline;
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.VodafoneRg};
  color: ${(props) => props.theme.colors.primaryColor};

  &:hover {
    color: ${(props) => props.theme.colors.primaryColor};
  }
`;

const StyledImage = styled.div<{ imageUrl: string }>`
  margin: 20px 30px 0 0;
  height: 150px;
  background: url('${(props) => props.imageUrl}') no-repeat center left;
  background-size: cover;

  @media ${(props) => props.theme.breakpoints.smOnly} {
    height: 120px;
  }
`;

const StyledButton = styled(Button)`
  color: black !important;
`;

const RequestFormIdaas: React.FC<IRequestFormProps> = (props) => {
  const themeContext = React.useContext(ThemeContext);
  const loginType = LoginTypeEnum.NUMBER;
  const [showHint, setShowHint] = React.useState(false);
  const { t } = useTranslation(['forgotPassword', 'component', 'form']);
  const { handleSubmit, control, errors, setError, setValue } = useForm({
    shouldUnregister: false,
  });
  const isMutating =
    useIsMutating({
      mutationKey: [RECOVERY_ELIGIBILITY_MUTATION_KEY],
    }) > 0;

  const captcha = useCaptcha();

  const handleReloadCaptcha = () => {
    if (captcha.isLoading || captcha.isRefetching) return;
    captcha.refetch();

    setValue('captcha', '');
  };

  const handleResetPasswordRedirect = () => {
    const resetPasswordUrl = createIdaasResetPasswordUrl({});
    window.open(resetPasswordUrl.toString(), '_self');
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!serviceNumberValidator(data.serviceNumber)) {
      setError('serviceNumber', {
        type: 'error',
        message: t('form:requestForm.fields.number.wrong-format'),
      });

      return;
    }

    if (props.onSubmit)
      props.onSubmit({
        data: { ...data, loginType },
        setError,
        setNewCaptcha: handleReloadCaptcha,
      });
  };

  return (
    <>
      <LoginTypeHeader>{t('form:requestForm.idaas.title')}</LoginTypeHeader>
      <p>{t('form:requestForm.idaas.description')}</p>
      <Message
        reverse
        type="info-blue"
        message={
          <span>
            <Trans
              i18nKey={'form:requestForm.is-email-login-message'}
              components={[
                <StyledLink onClick={() => handleResetPasswordRedirect()} />,
              ]}
            />
          </span>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)} name="requestForm">
        <FormField>
          <Controller
            as={Input}
            control={control}
            labelAsPlaceholder={false}
            id="serviceNumber"
            label={t('form:requestForm.fields.number.label')}
            placeholder={t('form:requestForm.fields.number.placeholder')}
            name="serviceNumber"
            rules={{
              required: { value: true, message: t('form:error.required') },
            }}
            message={errors.serviceNumber && errors.serviceNumber.message}
            errored={!!errors.serviceNumber}
            defaultValue=""
            disabled={isMutating}
          />
          {
            <MessageWrapper>
              <>
                <MessageHeader>
                  {t('form:requestForm.login-type-number.infobox-title')}
                </MessageHeader>
                <StyledUl>
                  <StyledLi>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'form:requestForm.login-type-number.infobox-list1',
                        ),
                      }}
                    />
                  </StyledLi>
                  <StyledLi>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'form:requestForm.login-type-number.infobox-list2',
                        ),
                      }}
                    />
                    <StyledLink
                      onClick={() => setShowHint((status) => !status)}
                    >
                      {t(
                        'form:requestForm.login-type-number.infobox-list2-link',
                      )}
                    </StyledLink>
                    {showHint && <StyledImage imageUrl={billExample} />}
                  </StyledLi>
                </StyledUl>
                <StyledButton
                  variant="secondary"
                  htmlElement="a"
                  htmlElementProps={{
                    href: t(
                      'form:requestForm.login-type-number.infobox-button-url',
                    ),
                    target: '_blank',
                  }}
                >
                  {t('form:requestForm.login-type-number.infobox-button')}
                </StyledButton>
              </>
            </MessageWrapper>
          }
        </FormField>

        <CaptchaTitleRow align="center">
          <LoginTypeHeader>
            {t('form:requestForm.enter-security-code')}
          </LoginTypeHeader>
          <CardTitle></CardTitle>
        </CaptchaTitleRow>
        <Row align="center">
          <ImageWrapper>
            {captcha.isLoading ? (
              <IconCircleLoader color={themeContext.colors.primaryColor} />
            ) : (
              captcha.data.captcha && (
                <CaptchaWrapper>
                  <img src={captcha.data.captcha} alt="reCaptcha" />
                </CaptchaWrapper>
              )
            )}
          </ImageWrapper>
          <RefreshButton onClick={isMutating ? () => {} : handleReloadCaptcha}>
            <Row align="center">
              {captcha.isLoading || captcha.isRefetching ? (
                <IconCircleLoader color={themeContext.colors.primaryColor} />
              ) : (
                <IconRefresh color={themeContext.colors.primaryColor} />
              )}
              <RefreshButtonText>
                {t('component:button.reload-image')}
              </RefreshButtonText>
            </Row>
          </RefreshButton>

          {captcha.isError && (
            <ErrorText>
              {t('form:requestForm.failed-loading-captcha')}
            </ErrorText>
          )}
        </Row>
        <VStack
          spacing="16px"
          style={{ paddingTop: '32px', paddingBottom: '16px' }}
        >
          <FormField>
            <Controller
              as={Input}
              control={control}
              labelAsPlaceholder={false}
              id="captcha"
              label={t('form:requestForm.fields.captcha.label')}
              placeholder={t('form:requestForm.fields.captcha.placeholder')}
              name="captcha"
              rules={{
                required: { value: true, message: t('form:error.required') },
              }}
              message={errors.captcha && errors.captcha.message}
              errored={!!errors.captcha}
              defaultValue=""
              disabled={isMutating}
            />
          </FormField>
          <FormField justify="center">
            <Button
              variant="primary"
              block
              htmlElement="button"
              htmlElementProps={{ type: 'submit' }}
              disabled={isMutating}
            >
              {isMutating
                ? t('component:button.loading')
                : t('component:button.continue')}
            </Button>
          </FormField>
        </VStack>
      </form>
    </>
  );
};

export default RequestFormIdaas;
