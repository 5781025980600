import {
  BlogIcon,
  Button,
  IconCircleLoader,
  ICoreOptionProps,
  Select,
  Text,
} from '@vodafone/red-white';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VStack } from 'src/components/Layout';
import { StyledLink } from 'src/components/PasswordRecovery/RequestForm/RequestFormIdaas';
import { RoutesEnum } from 'src/config/routes';
import { useUpdateDefaultService } from 'src/features/accountManagement/hooks';
import { useGetUserAssets, UserAsset } from 'src/features/profile';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import selectLanguage from 'src/store/language/languageSelector';
import { closeModal, openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import UserAuthorizationRoleEnum from 'src/store/user/types/UserAuthorizationRoleEnum';
import { fetchSession } from 'src/store/user/userActions';
import userSelector from 'src/store/user/userSelector';
import { SelectWithInput } from 'src/ui/components/SelectWithInput';
import { redirect } from 'src/utils/redirect';
import styled from 'styled-components';

const ResponsiveButton = styled(Button)`
  width: 180px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

type Props = {
  accountInfoMessage?: ReactNode;
  serviceInfoMessage?: ReactNode;
  displayLabels?: boolean;
  onErrorTransKey: string;
};

export const SelectPrimaryService = ({
  accountInfoMessage,
  serviceInfoMessage,
  onErrorTransKey,
  displayLabels,
}: Props) => {
  const user = useSelector(userSelector);
  const dispatch = useAppDispatch();
  const [selectedGrantedRole, setSelectedGrantedRole] = useState(
    user.actualGrantedRole,
  );
  const { mutate, isPending } = useUpdateDefaultService();
  const { data } = useGetUserAssets();
  const [selectedUserAsset, setSelectedUserAsset] = useState('');
  const { t } = useTranslation('personalAccount');
  const language = useSelector(selectLanguage);
  const grantedRolesSelectboxValues = useMemo(
    () =>
      user.grantedRoles
        .sort((a, b) => a.type.localeCompare(b.type))
        .map(({ type, serviceNumber, crmId, siebelName }) => ({
          label: `${siebelName ? siebelName + ' - ' : ''}${
            type === UserAuthorizationRoleEnum.User ? serviceNumber : crmId
          } - ${t(`profile.role-${type}`)}`,
          value: crmId,
        })) || [],
    [user.grantedRoles],
  );

  const validAssetTypes = ['broadband', 'fixed', 'tv', 'mobile', 'sec', 'fms'];
  const userAssetsSelectboxValues = useMemo(
    () =>
      data?.userAssets.user
        .filter((asset) => asset.relatedRole.id === selectedGrantedRole.crmId)
        .filter((asset) => validAssetTypes.includes(asset.assetType))
        ?.map((asset: UserAsset) => ({
          value: `${asset.name ? asset.name + ' - ' : ''}${asset.id}`,
          roleId: asset.id,
          assetType: asset.assetType,
        })) || [],
    [selectedGrantedRole, data],
  );

  const grantedRoleSelectboxHandle = (item: ICoreOptionProps) => {
    setSelectedGrantedRole(
      user.grantedRoles.find((grantedRole) => item.value === grantedRole.crmId),
    );
  };

  const selectedAssetSelectboxHandle = (asset: string) => {
    setSelectedUserAsset(asset || '');
  };

  const handleClick = () => {
    mutate(
      {
        primaryServiceServiceNumber: selectedUserAsset,
      },
      {
        onSuccess: () => {
          dispatch(fetchSession(true));
          dispatch(closeModal({ type: ModalType.PrimaryServiceModal }));
          dispatch(
            openModal({
              type: ModalType.MessageModal,
              options: {
                type: 'success',
                title: t('primary-service.modal.title'),
                message: t('primary-service.success'),
                icon: <BlogIcon size={96} />,
                closeBtnText: t('primary-service.modal.close'),
              },
            }),
          );
        },
        onError: () => {
          dispatch(closeModal({ type: ModalType.PrimaryServiceModal }));
          dispatch(
            openModal({
              type: ModalType.MessageModal,
              options: {
                type: 'error',
                closeBtnText: t('primary-service.modal.close'),
                title: t('primary-service.modal.title'),
                icon: <BlogIcon size={96} />,
                message: (
                  <div style={{ display: 'block', textAlign: 'left' }}>
                    <Trans
                      i18nKey={`personalAccount:${onErrorTransKey}`}
                      components={[
                        <StyledLink
                          onClick={() =>
                            redirect({
                              internalRouteId:
                                RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
                              language,
                            })
                          }
                        />,
                      ]}
                    />
                  </div>
                ),
              },
            }),
          );
        },
      },
    );
  };

  // set initial value in assets selectbox + set valu in assets selectbox when 1. selectbox changed
  useEffect(() => {
    const selectedValue = userAssetsSelectboxValues.find(
      (item) => item.roleId === user.defaultServiceNumber,
    );
    setSelectedUserAsset(
      selectedValue?.value || userAssetsSelectboxValues?.[0]?.value || '',
    );
  }, [selectedGrantedRole, userAssetsSelectboxValues]);

  useEffect(() => {
    const defaultValue = data?.userAssets.user.find(
      (item) => item?.id === user.defaultServiceNumber,
    )?.relatedRole?.id;

    const selected = user.grantedRoles.find(
      (grantedRole) => grantedRole.crmId === defaultValue,
    );
    if (selected) {
      setSelectedGrantedRole(selected);
    }
  }, [data]);

  return (
    <VStack spacing="24px" style={{ width: '100%' }} align="center">
      {accountInfoMessage}

      <div style={{ width: '100%', maxWidth: 400, textAlign: 'center' }}>
        {displayLabels && (
          <Text fontSize={18} fontFamily="VodafoneRgBd">
            {t('primary-service.default-account')}
          </Text>
        )}

        <div style={{ textAlign: 'left' }}>
          <Select
            key={selectedGrantedRole.crmId}
            options={grantedRolesSelectboxValues}
            onChange={grantedRoleSelectboxHandle}
            value={selectedGrantedRole.crmId}
            disabled={!data?.userAssets || isPending}
            style={{ textOverflow: 'ellipsis', paddingRight: 45 }}
          />
        </div>
      </div>

      {serviceInfoMessage}
      <>
        <div style={{ maxWidth: 400, width: '100%', textAlign: 'center' }}>
          {displayLabels && (
            <Text fontSize={18} fontFamily="VodafoneRgBd">
              {t('primary-service.default-service')}
            </Text>
          )}

          <SelectWithInput
            options={userAssetsSelectboxValues}
            onChange={selectedAssetSelectboxHandle}
            value={selectedUserAsset}
            disabled={isPending}
            debounceTimeout={0}
          />
        </div>
        <ResponsiveButton
          variant={'primary'}
          disabled={!(selectedUserAsset && selectedGrantedRole) || isPending}
          onClick={handleClick}
        >
          {isPending ? (
            <IconCircleLoader color={'white'} />
          ) : (
            t('primary-service.save-settings')
          )}
        </ResponsiveButton>
      </>
    </VStack>
  );
};
