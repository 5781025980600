import { ActionCreator } from 'redux';
import { getSession } from 'src/api/core';
import { ISessionResponse } from 'src/api/core/response/sessionResponseInterface';
import {
  getLargeBillingAccounts,
  getLargeSubscribers,
} from 'src/api/largeAccounts';
import { ILargeBillingAccountsResponse } from 'src/api/largeAccounts/response/largeBillingAccountsResponseInterface';
import { ILargeSubscribersResponse } from 'src/api/largeAccounts/response/largeSubscribersResponseInterface';
import { deleteProfileAccount, saveDefaultRole } from 'src/api/personalAccount';
import { setAcrAndOptionSettings } from 'src/utils/analytics/utils/functions';
import { ResponseStatusEnum } from 'src/utils/api/enums/responseStatusEnum';
import { redirect } from 'src/utils/redirect';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import {
  IChangeDefaultRoleBeginAction,
  IChangeDefaultRoleFailureAction,
  IChangeDefaultRoleSuccessAction,
} from './types/changeDefaultRoleActionInterface';
import {
  IDeleteProfileBeginAction,
  IDeleteProfileFailureAction,
  IDeleteProfileSuccessAction,
} from './types/deleteProfileActionInterface';
import {
  IFetchLargeBillingAccountsBeginAction,
  IFetchLargeBillingAccountsFailureAction,
  IFetchLargeBillingAccountsSuccessAction,
} from './types/fetchLargeBillingAccountsActionInterface';
import {
  IFetchLargeSubscribersBeginAction,
  IFetchLargeSubscribersFailureAction,
  IFetchLargeSubscribersSuccessAction,
} from './types/fetchLargeSubscribersActionInterface';
import { ILogoutUserAction } from './types/logoutUserActionInterface';
import {
  IFetchSessionBeginAction,
  IFetchSessionFailureAction,
  IFetchSessionSuccessAction,
  ISwitchBillingAccountAction,
  IUpdateContactNumberAction,
} from './types/sessionActionInterfaces';
import { SessionActionTypes } from './types/sessionActionTypes';

export const logoutUserAction: ActionCreator<ILogoutUserAction> = () => ({
  type: SessionActionTypes.USER_LOGOUT,
});

export const fetchSessionBegin: ActionCreator<
  IFetchSessionBeginAction
> = () => ({
  type: SessionActionTypes.FETCH_SESSION_BEGIN,
});

export const fetchSessionSuccess: ActionCreator<IFetchSessionSuccessAction> = (
  payload: ISessionResponse,
) => ({
  type: SessionActionTypes.FETCH_SESSION_SUCCESS,
  payload,
});

export const fetchSessionFailure: ActionCreator<
  IFetchSessionFailureAction
> = () => ({
  type: SessionActionTypes.FETCH_SESSION_FAILURE,
});

export const fetchSession =
  (silent = false) =>
  async (dispatch) => {
    if (!silent) {
      dispatch(fetchSessionBegin());
      dispatch(loadingStart());
    }

    return getSession()
      .then((response) => {
        dispatch(fetchSessionSuccess(response.data));
        setAcrAndOptionSettings(response.data);
        !silent && dispatch(loadingStop());
      })
      .catch(() => {
        if (!silent) {
          dispatch(fetchSessionFailure());
          dispatch(loadingStop());
        }
      });
  };

export const changeDefaultRoleBegin: ActionCreator<
  IChangeDefaultRoleBeginAction
> = () => ({
  type: SessionActionTypes.CHANGE_DEFAULT_ROLE_BEGIN,
});

export const changeDefaultRoleSuccess: ActionCreator<
  IChangeDefaultRoleSuccessAction
> = (payload) => ({
  type: SessionActionTypes.CHANGE_DEFAULT_ROLE_SUCCESS,
  payload,
});

export const changeDefaultRoleFailure: ActionCreator<
  IChangeDefaultRoleFailureAction
> = (payload) => ({
  type: SessionActionTypes.CHANGE_DEFAULT_ROLE_FAILURE,
  payload,
});

export const changeDefaultRole =
  (defaultRoleId: string) => async (dispatch) => {
    dispatch(changeDefaultRoleBegin());

    return saveDefaultRole(defaultRoleId)
      .then((response) => {
        if (
          response.status !== 200 ||
          response.data.status !== ResponseStatusEnum.OK
        ) {
          dispatch(
            changeDefaultRoleFailure({
              errorMessage: response.data.message,
            }),
          );
        }
        if (
          response.status === 200 &&
          response.data.status === ResponseStatusEnum.OK
        ) {
          dispatch(
            changeDefaultRoleSuccess({
              defaultRoleId,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          changeDefaultRoleFailure({
            errorMessage: err.message,
          }),
        );
      });
  };

export const deleteProfileBegin: ActionCreator<
  IDeleteProfileBeginAction
> = () => ({
  type: SessionActionTypes.DELETE_PROFILE_BEGIN,
});

export const deleteProfileSuccess: ActionCreator<
  IDeleteProfileSuccessAction
> = () => ({
  type: SessionActionTypes.DELETE_PROFILE_SUCCESS,
});

export const deleteProfileFailure: ActionCreator<
  IDeleteProfileFailureAction
> = (payload) => ({
  type: SessionActionTypes.DELETE_PROFILE_FAILURE,
  payload,
});

export const deleteProfile = () => async (dispatch) => {
  dispatch(deleteProfileBegin());

  return deleteProfileAccount()
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.status === ResponseStatusEnum.REDIRECT
      ) {
        dispatch(deleteProfileSuccess());
        dispatch(logoutUserAction());
        redirect({ url: response.data.redirectUrl }); // redirect from SPA to FE
      } else {
        dispatch(
          deleteProfileFailure({
            errorMessage: response.data.message,
          }),
        );
      }
    })
    .catch((err) => {
      dispatch(
        deleteProfileFailure({
          errorMessage: err.message,
        }),
      );
    });
};

export const fetchLargeSubscribersBegin: ActionCreator<
  IFetchLargeSubscribersBeginAction
> = () => ({
  type: SessionActionTypes.FETCH_LARGE_SUBSCRIBERS_BEGIN,
});

export const fetchLargeSubscribersSuccess: ActionCreator<
  IFetchLargeSubscribersSuccessAction
> = (caNumber: string, payload: ILargeSubscribersResponse) => ({
  type: SessionActionTypes.FETCH_LARGE_SUBSCRIBERS_SUCCESS,
  payload,
  caNumber,
});

export const fetchLargeSubscribersFailure: ActionCreator<
  IFetchLargeSubscribersFailureAction
> = (payload) => ({
  type: SessionActionTypes.FETCH_LARGE_SUBSCRIBERS_FAILURE,
  payload,
});

export const fetchLargeSubscribers =
  (customerAccountNumber: string) => async (dispatch) => {
    dispatch(fetchLargeSubscribersBegin());

    return getLargeSubscribers(customerAccountNumber)
      .then((response) => {
        dispatch(
          fetchLargeSubscribersSuccess(customerAccountNumber, response.data),
        );
      })
      .catch((error) => {
        dispatch(
          fetchLargeSubscribersFailure({
            code:
              error.response &&
              error.response.data &&
              error.response.data.message &&
              error.response.data.message.code
                ? error.response.data.message.code
                : null,
            description:
              error.response &&
              error.response.data &&
              error.response.data.message &&
              error.response.data.message.description
                ? error.response.data.message.description
                : null,
          }),
        );
      });
  };

export const fetchLargeBillingAccountsBegin: ActionCreator<
  IFetchLargeBillingAccountsBeginAction
> = () => ({
  type: SessionActionTypes.FETCH_LARGE_BILLING_ACCOUNTS_BEGIN,
});

export const fetchLargeBillingAccountsSuccess: ActionCreator<
  IFetchLargeBillingAccountsSuccessAction
> = (caNumber: string, payload: ILargeBillingAccountsResponse) => ({
  type: SessionActionTypes.FETCH_LARGE_BILLING_ACCOUNTS_SUCCESS,
  caNumber,
  payload,
});

export const fetchLargeBillingAccountsFailure: ActionCreator<
  IFetchLargeBillingAccountsFailureAction
> = (payload) => ({
  type: SessionActionTypes.FETCH_LARGE_BILLING_ACCOUNTS_FAILURE,
  payload,
});

export const fetchLargeBillingAccounts =
  (customerAccountNumber: string) => async (dispatch) => {
    dispatch(fetchLargeBillingAccountsBegin());

    return getLargeBillingAccounts(customerAccountNumber)
      .then((response) => {
        dispatch(
          fetchLargeBillingAccountsSuccess(
            customerAccountNumber,
            response.data,
          ),
        );
      })
      .catch(() => {
        dispatch(fetchLargeBillingAccountsFailure());
      });
  };

export const switchBillingAccount: ActionCreator<
  ISwitchBillingAccountAction
> = (payload) => ({
  type: SessionActionTypes.SWITCH_BILLING_ACCOUNT,
  payload,
});

export const updateContactNumber: ActionCreator<IUpdateContactNumberAction> = (
  payload,
) => ({
  type: SessionActionTypes.UPDATE_CONTACT_NUMBER,
  payload,
});
