import { Button, Card, Message, Text } from '@vodafone/red-white';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import styled from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 20px 0 0 0;
  text-align: center;
`;

const IconWrapper = styled.div`
  @media ${(props) => props.theme.breakpoints.xsOnly} {
    display: none;
  }
`;

const ResponsiveButton = styled(Button)`
  width: 180px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const MessageModalContent = ({
  options,
  onClose,
}: ModalContentProps) => {
  return (
    <Card
      style={{
        width: '100%',
        maxWidth: 662,
        boxShadow: 'none',
        maxHeight: '80vh',
      }}
    >
      <CustomCardPadding>
        {options.icon && <IconWrapper>{options.icon}</IconWrapper>}
        {options.title && (
          <Text fontFamily="VodafoneRgBd" fontSize={32}>
            {options.title}
          </Text>
        )}
        <div style={{ marginTop: '30px' }}>
          <Message type={options.type} message={options.message} />
        </div>
        {options.closeBtnText && (
          <ResponsiveButton variant={'primary'} onClick={() => onClose()}>
            {options.closeBtnText}
          </ResponsiveButton>
        )}
      </CustomCardPadding>
    </Card>
  );
};
