import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export const PASSWORD_RECOVERY_ELIGIBILITY_ENDPOINT =
  '/muj/rapi/form/recovery_eligibility';
export const RECOVERY_ELIGIBILITY_MUTATION_KEY = 'recoveryEligibilityMutation';
export const RECOVERY_ELIGIBILITY_KEY = 'recoveryEligibility';

type RecoveryEligibilityRequest = {
  captcha: string;
  disableDistantContactNumber: string;
  serviceNumber: string;
};

type RecoveryEligibilityResponse = {
  message?: string;
  messageCode?: string;
  messageDescription?: string;
  status?: string;
  recaptchaEnabled?: boolean;
  code?: string;
  form?: {
    validationErrors: {
      [key: string]: string;
    };
  };
  validationErrors?: {
    [key: string]: string;
  };
  passwordRecoverySettings?: {
    authorizationRole: {
      value: string;
      isAfterBlur: boolean;
    };
    securityQuestions: {
      value: string;
      isAfterBlur: boolean;
    }[];
    recoveryChannels: {
      value: string;
      isAfterBlur: boolean;
    }[];
  }[];
};

const getPasswordRecoveryEligibility = async (
  request: RecoveryEligibilityRequest,
): Promise<RecoveryEligibilityResponse> => {
  const response = await api
    .post<RecoveryEligibilityResponse>({
      url: PASSWORD_RECOVERY_ELIGIBILITY_ENDPOINT,
      data: request,
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });

  return response.data;
};

export const useRecoveryEligibility = () => {
  const queryClient = useQueryClient();

  return useMutation<
    RecoveryEligibilityResponse,
    void,
    RecoveryEligibilityRequest
  >({
    mutationFn: getPasswordRecoveryEligibility,
    mutationKey: [RECOVERY_ELIGIBILITY_MUTATION_KEY],
    onSuccess: (data) => {
      return queryClient.setQueryData([RECOVERY_ELIGIBILITY_KEY], data);
    },
  });
};

export const useRecoveryEligibilityData = () => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData<RecoveryEligibilityResponse>([
    RECOVERY_ELIGIBILITY_KEY,
  ]);
};
