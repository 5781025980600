export const IDAAS_LOGIN_NEXT_URL = '/muj/auth';

export const IDAAS_RESET_PASSWORD_URL = '/muj/auth/reset-password';

export const IDAAS_LOGIN_REGISTER_URL = '/muj/auth/register';

export const IDAAS_MFA_PHONE_NUMBER_UPDATE = '/muj/profile/update/msisdn';

export enum IDAAS_URL_PARAMETERS {
  CC = 'cc',
  T_V_ID = 't_v_id',
  A_V_ID = 'a_v_id',
  LOGIN_HINT = 'login_hint',
  REDIRECT_URI = 'redirect_uri',
  UI_LOCALES = 'ui_locales',
  FLOW = 'flow',
}
