import {
  ModalSizeEnum,
  ThemeColorTypes,
  ThemeRedesign,
} from '@vodafone/red-white';
import { IModalOptionMap } from 'src/components/Modal/types/modalOptionInterfaces';
import ModalType from 'src/store/modal/types/ModalType';

const modalOptionsMap: IModalOptionMap = {
  [ModalType.PaymentInfo]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    size: ModalSizeEnum.xl,
  },
  [ModalType.PaymentStatus]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    style: {
      width: 768,
    },
    closeIconColor: ThemeRedesign.colors.darkGrey as ThemeColorTypes,
  },
  [ModalType.RemoveAccess]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    style: {
      maxWidth: 550,
      width: '100%',
    },
    closeIconColor: ThemeRedesign.colors.darkGrey as ThemeColorTypes,
  },
  [ModalType.GrantAccess]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    style: {
      maxWidth: 960,
      width: '100%',
      borderRadius: 0,
    },
    closeIconColor: ThemeRedesign.colors.darkGrey as ThemeColorTypes,
  },
  [ModalType.ShowPassword]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    style: {
      maxWidth: 550,
      width: '100%',
    },
    closeIconColor: ThemeRedesign.colors.darkGrey as ThemeColorTypes,
  },
  [ModalType.ChangeNickname]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    style: {
      maxWidth: 550,
      width: '100%',
    },
    closeIconColor: ThemeRedesign.colors.darkGrey as ThemeColorTypes,
  },
  [ModalType.TransferESim]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    style: {
      maxWidth: 700,
      width: '100%',
      maxHeight: '90vh',
    },
    closeIconColor: ThemeRedesign.colors.darkGrey as ThemeColorTypes,
  },
  [ModalType.ReceivedPayments]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    style: {
      width: 600,
    },
  },
  [ModalType.UPCBillingHistory]: {
    compactContent: true,
    showCloseIcon: true,
    closeIconColor: ThemeRedesign.colors.white as ThemeColorTypes,
    dark: true,
    style: {
      width: 960,
      background: 'transparent',
      borderRadius: 0,
    },
  },
  [ModalType.DifferentPayment]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    size: ModalSizeEnum.xl,
  },
  [ModalType.LostStolenConfirm]: {
    compact: true,
    showCloseIcon: true,
  },
  [ModalType.CustomerIdentityLoginBanner]: {
    preventCloseWhenClickOnOverlay: true,
    preventCloseWhenKeypressEscape: true,
    showCloseIcon: true,
    style: {
      width: 600,
    },
    analyticsProp49Name: 'CIpopupforBA',
  },
  [ModalType.FbbModemSettings]: {
    showCloseIcon: true,
    style: {
      height: '100%',
      maxHeight: 660,
      padding: '0 20px',
    },
  },
  [ModalType.VtvSetPassword]: {
    showCloseIcon: true,
    compactContent: true,
    size: ModalSizeEnum.md,
    style: {
      height: '100%',
      maxHeight: 660,
      padding: '0 20px',
      overflow: 'auto',
    },
  },
  [ModalType.VtvSetPasswordStatus]: {
    showCloseIcon: true,
    compact: true,
    size: ModalSizeEnum.md,
    style: {
      paddingTop: '0',
    },
  },
  [ModalType.OrderNewESim]: {
    showCloseIcon: true,
    compactContent: true,
    style: {
      height: '100%',
      maxHeight: 600,
      overflow: 'auto',
    },
    disableDefaultAnalytics: true,
  },
  [ModalType.ActivateDeletedESim]: {
    showCloseIcon: true,
    compactContent: true,
    style: {
      height: '100%',
      maxHeight: 600,
      overflow: 'auto',
    },
  },
  [ModalType.Mfa]: {
    disableDefaultAnalytics: true,
    showCloseIcon: true,
  },
  [ModalType.DiscountCodes]: {
    showCloseIcon: true,
    compactContent: true,
    size: ModalSizeEnum.md,
    style: {
      height: '100%',
      maxHeight: 450,
      padding: '10px',
      overflow: 'auto',
    },
  },
  [ModalType.RoamingCalc]: {
    showCloseIcon: true,
    compactContent: true,
    style: {
      maxHeight: 550,
      maxWidth: 926,
      padding: '20px',
      height: '100%',
      width: '100%',
      overflow: 'auto',
    },
  },
  [ModalType.ConnectedDevicesToModem]: {
    showCloseIcon: true,
    size: ModalSizeEnum.lg,
    style: {
      height: '100%',
      maxHeight: 650,
      overflow: 'auto',
    },
  },
  [ModalType.Video]: {
    disableDefaultAnalytics: true,
  },
  [ModalType.MessageModal]: {
    compact: true,
    compactContent: true,
    showCloseIcon: true,
    closeIconColor: ThemeRedesign.colors.darkGrey as ThemeColorTypes,
  },
};

export default modalOptionsMap;
