import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { CACHE_TIME_FOR_UNREAD_VF_NEWS } from 'src/config/config';
import { useCoreOutage } from 'src/features/core';
import userSelector from 'src/store/user/userSelector';
import api from 'src/utils/api/api';

export const GET_UNREAD_MESSAGES_COUNT_ENDPOINT =
  '/muj/rapi/messages/unread/count';
export const UNREAD_MESSAGES_COUNT_KEY = 'getUnreadMessagesCount';

type GetUnreadMessagesCountResponse = {
  result: string;
  count: number;
};

const getUnreadMessagesCount =
  async (): Promise<GetUnreadMessagesCountResponse> => {
    const { data } = await api.get({ url: GET_UNREAD_MESSAGES_COUNT_ENDPOINT });

    return data;
  };

export const useUnreadMessagesCount = () => {
  const outage = useCoreOutage();
  const user = useSelector(userSelector);

  return useQuery({
    queryFn: () => getUnreadMessagesCount(),
    queryKey: [UNREAD_MESSAGES_COUNT_KEY],
    gcTime: CACHE_TIME_FOR_UNREAD_VF_NEWS,
    enabled: user.signedIn && !!user.subscription && !outage.data.isReadMode,
  });
};
